import { css } from '@emotion/react'
import { NextPage } from 'next'
import React, { memo } from 'react'
import { fill_vertical_cross_center, flex } from '~/modules/AppLayout/FlexGridCss'

import { useTradingSummaryHashState } from '~/modules/tradeSheet/tradingAnalyzer/useTradingSummaryHashState'

import CategoryPieChart from '~/modules/tradeSheet/shared/CategoryPieChart'
import CustomizedRadarChart from '~/modules/tradeSheet/shared/CustomizedRadarChart'
import TimeRangeTabs from '~/modules/tradeSheet/shared/TimeRangeTabs'
import AccumulatedProfitChart from '~/modules/tradeSheet/tradingAnalyzer/AccumulatedProfitChart'
import DailyPerformanceChart from '~/modules/tradeSheet/tradingAnalyzer/DailyPerformanceChart'
import MaxDrawdown from '~/modules/tradeSheet/tradingAnalyzer/MaxDrawdown'
import TradeFrequency from '~/modules/tradeSheet/tradingAnalyzer/TradeFrequency'
import TotalProfit from '~/modules/tradeSheet/tradingAnalyzer/TotalProfit'
import TopThreePnl from '~/modules/tradeSheet/tradingAnalyzer/TopThreePnl'
import UploadDnD from '~/modules/tradeSheet/tradingAnalyzer/UploadDnD'

// css
import {
  itemTitleCss,
  sectionCss,
  chartCss,
  columnCss,
  narrowColumnCss,
  top3SectionCss,
  top3ColumnCss,
} from '~/modules/tradeSheet/tradingAnalyzer/tradingAnalyzerCss'
import { globalGreen, globalRed } from '~/modules/AppLayout/Colors'
import { usePageThemeMode } from '~/pages/huanan_template/_private/usePageThemeMode'

export const TradingSummary = memo(function TradingSummaryNextPage() {
  const rootCss = css`
    ${fill_vertical_cross_center};
    padding: 32px 0 0;
    position: relative;
    margin: 0 auto;
    background-color: #161616;

    & > div p {
      color: #ffffff !important;
      font-size: 14px;
    }
  `

  usePageThemeMode('dark')

  return (
    <useTradingSummaryHashState.Provider>
      <div css={rootCss}>
        <UploadDnD warringDescription='圖表由華南好神期平台匯出表格而轉換製成，僅提供參考不保證資料準確性' />
        <Sheet />
      </div>
    </useTradingSummaryHashState.Provider>
  )
})

const Sheet = memo(function Sheet() {
  const { state } = useTradingSummaryHashState.useContainer()
  return state.isDataReady ? (
    <>
      <TimeRangeTabs />
      <DataTemplate />
    </>
  ) : null
})

const DataTemplate = memo(function DataTemplate() {
  const { state } = useTradingSummaryHashState.useContainer()
  if (!state.isDataReady) return null
  return (
    <div css={flex.v.allCenter}>
      <div css={narrowColumnCss}>
        <div css={sectionCss}>
          <div css={itemTitleCss()}>淨損益</div>
          <TotalProfit />
        </div>
        <div css={sectionCss}>
          <div css={itemTitleCss()}>最大回徹</div>
          <MaxDrawdown />
        </div>
        <div css={sectionCss}>
          <div css={itemTitleCss()}>每日平均交易口數</div>
          <TradeFrequency.Lot />
        </div>

        <div css={sectionCss}>
          <div css={itemTitleCss()}>每日平均交易次數</div>
          <TradeFrequency.Num />
        </div>
      </div>
      <div css={columnCss}>
        <div css={sectionCss}>
          <div css={itemTitleCss()}>權益曲線</div>
          <div css={chartCss}>
            <AccumulatedProfitChart />
          </div>
        </div>
        <div css={sectionCss}>
          <div css={itemTitleCss()}>每日損益</div>
          <div css={chartCss}>
            <DailyPerformanceChart data={state.dailyProfit ?? []} />
          </div>
        </div>
      </div>

      <div css={columnCss}>
        <div css={sectionCss}>
          <div css={itemTitleCss()}>交易數值分析</div>
          <div css={chartCss}>
            <CustomizedRadarChart
              details={state.analysisUIDetails}
              fontSize={13}
            />
          </div>
        </div>

        <div css={sectionCss}>
          <div css={itemTitleCss()}>交易商品比重(前10名)</div>
          <div css={chartCss}>
            <CategoryPieChart
              data={state.propotionUIData}
              width={320}
              height={320}
            />
          </div>
        </div>
      </div>
      <div css={top3ColumnCss}>
        <div css={top3SectionCss}>
          <div css={itemTitleCss(globalRed.rA400)}>前三大獲利產品及其報酬金額</div>
          <TopThreePnl.Profit />
        </div>
        <div css={top3SectionCss}>
          <div css={itemTitleCss(globalGreen.g600)}>前三大虧損產品及其報酬金額</div>
          <TopThreePnl.Loss />
        </div>
      </div>
    </div>
  )
})
